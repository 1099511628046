<template>
  <span>Member Profile</span>
</template>

<script>

export default {
  data () {
    return {
    };
  }
  // beforeCreate () {
  //   console.log('[member]][beforeCreate] ... ');
  // },
  // created () {
  //   console.log('[member][created] ... ');
  // },
  // beforeMount () {
  //   console.log('[member][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[member][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[member][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[member][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
